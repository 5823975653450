/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateWorkspaceDTO = {
    country: string;
    description?: string;
    name: string;
    status?: CreateWorkspaceDTO.status;
    strategy?: CreateWorkspaceDTO.strategy;
    type?: CreateWorkspaceDTO.type;
};

export namespace CreateWorkspaceDTO {

    export enum status {
        ACTIVE = 'ACTIVE',
        ARCHIVED = 'ARCHIVED',
        DELETED = 'DELETED',
    }

    export enum strategy {
        ON_DEMAND = 'ON_DEMAND',
        PER_USER = 'PER_USER',
        POOL = 'POOL',
    }

    export enum type {
        B2B = 'B2B',
        B2C = 'B2C',
    }


}

