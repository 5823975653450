/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { WorkspaceDTO } from './WorkspaceDTO';

export type MeUserDTO = {
    createdAt: string;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    userMetadata: Record<string, any>;
    status: MeUserDTO.status;
    updatedAt?: string;
    workspaceList: Array<WorkspaceDTO>;
};

export namespace MeUserDTO {

    export enum status {
        ACTIVE = 'ACTIVE',
        ARCHIVED = 'ARCHIVED',
        DELETED = 'DELETED',
    }


}

