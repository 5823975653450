import { Box, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import * as Print from 'expo-print';
import { ArrowLeft, DownloadIcon } from 'lucide-react-native';
import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';

import Bioeureka from '../assets/bioeureka-primary.svg';
import { PrimaryButton } from '../components/buttons/PrimaryButton';
import { TerciaryButton } from '../components/buttons/TerciaryButton';
import { TranslationContext } from '../contexts/translations/Translation';
import { UserContext } from '../contexts/User';
import type { IAnalysisExportPageProps } from '../routes/Routes';
import { COLORS } from '../theme/colors';

// TODO: Refactor this page, it's old and not well structured
export const AnalysisExportPage: FC<IAnalysisExportPageProps> = (props: IAnalysisExportPageProps) => {
  const { navigation } = props;
  const { analysis } = props.route.params;
  const { user } = useContext(UserContext) ?? {};

  const [image] = useState<string>(analysis.imageUrl ?? '');
  const [showBackArrow, setShowBackArrow] = useState<boolean>(false);

  const { i18n } = useContext(TranslationContext);
  // TODO: Add as constants
  const BIOEUREKA_EMAIL = 'samples@bioeureka.ca';
  const BIOEUREKA_PHONE = '+15145555050';

  // TODO: Fetch from API
  const workspace = {
    id: i18n?.t('export.form.sample-information-section.laboratory-id') || '',
    name: i18n?.t('export.form.client-section.client-name') || '',
    address: i18n?.t('export.form.client-section.client-address') || '',
    phoneNumber: i18n?.t('export.form.client-section.client-phone') || '',
    city: 'Saint-Nicolas',
    country: 'Canada',
    postalCode: 'G7A 2P4',
    phone: '+15145555050',
  };
  const TEMPERATURE = i18n?.t('export.form.sample-information-section.temperature') || '';
  const METHOD_REF = i18n?.t('export.form.sample-information-section.method-reference') || '';
  const SAMPLE_ID = analysis.properties?.sampleId ?? analysis.code;
  const LABO_ID = analysis.properties?.laboratoryId ?? workspace.name;
  const RECEIVED_DATE = new Date(analysis.properties?.receptionDate ?? analysis.createdAt ?? new Date()).toLocaleDateString();
  const AUTHORIZED_BY = i18n?.t('export.form.client-section.client-name') || '';

  const createPDF = async () => {
    if (analysis?.imageUrl) {
      try {
        // hide header
        await Print.printToFileAsync({ html: `<p>allo</p>` });
        // show heder again
      } catch (error) {
        console.error(error);
      }
    }
  };

  const runCreatePDF = async () => {
    if (image) {
      setShowBackArrow(false);
      navigation.setOptions({ headerShown: false });
      setTimeout(async () => {
        await createPDF();
        navigation.setOptions({ headerShown: true });
        setShowBackArrow(true);
      }, 1_000);
    }
  };

  useEffect(() => {
    runCreatePDF();
  }, [image]);

  const SectionHeader = ({ header }: { header: string[] }) => {
    return (
      <Box style={{ flexDirection: 'row', marginHorizontal: 10, padding: 5, paddingTop: 20, borderBottomWidth: 1 }}>
        {header.map((item, index) => {
          return (
            <Text key={index} style={{ fontWeight: 'bold', width: '50%', fontSize: 24 }}>
              {item}
            </Text>
          );
        })}
      </Box>
    );
  };

  const SectionHalfAndHalf = ({ fieldName, fieldValue }: { fieldName: string[]; fieldValue: string[] }) => {
    return (
      <Box style={{ flexDirection: 'row', marginHorizontal: 10, padding: 5, paddingTop: 5 }}>
        {fieldName?.map((item: string, index) => (
          <Box key={index} style={{ display: 'flex', width: '50%', alignContent: 'flex-start' }}>
            <Text>{item}</Text>
            {fieldValue[index] && <Text style={{ fontWeight: 'bold' }}>{fieldValue[index]}</Text>}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <>
      <VStack>
        {showBackArrow && (
          <HStack justifyContent="space-between">
            <TerciaryButton
              style={{ margin: 10, padding: 0 }}
              leftIcon={() => <ArrowLeft size="16px" strokeWidth="1.25px" color={COLORS.ACCENT[100]} />}
              content={i18n.t('export.go-back')}
              onPress={async () => navigation.goBack()}
            />
            <PrimaryButton
              style={{ margin: 20 }}
              leftIcon={() => <DownloadIcon size="16px" strokeWidth="1.25px" style={{ marginRight: 5 }} color={COLORS.NEUTRAL[0]} />}
              content={i18n.t('export.download-again')}
              onPress={async () => runCreatePDF()}
            />
          </HStack>
        )}
        <Box style={{ flexDirection: 'row', justifyContent: 'space-around', marginHorizontal: 10, padding: 5 }}>
          {image && <img src={Bioeureka} height={100} width={200} style={{ borderRadius: 10 }} />}

          {image && <img crossOrigin="anonymous" src={image} height={200} width={200} style={{ borderRadius: '20px' }} />}
          <VStack style={{ paddingTop: 40 }}>
            <Text>{i18n?.t('export.header.phone', { phone: BIOEUREKA_PHONE })}</Text>
            <Text>{BIOEUREKA_EMAIL}</Text>
          </VStack>
        </Box>
        <Box style={{ flexDirection: 'row', justifyContent: 'center', marginHorizontal: 10, padding: 5 }}>
          <Heading size="xl">{i18n?.t('export.header.title')}</Heading>
        </Box>

        {/* Client & COA section */}
        <SectionHeader header={[i18n?.t('export.form.client-section.title') || '', i18n?.t('export.form.coa-section.title') || '']} />
        <SectionHalfAndHalf fieldName={[workspace.name, i18n?.t('export.form.coa-section.coa-number') || '']} fieldValue={['', `${analysis.code}`]} />
        <SectionHalfAndHalf fieldName={[workspace.address, i18n?.t('export.form.coa-section.coa-date') || '']} fieldValue={['', new Date(analysis.updatedAt ?? new Date()).toLocaleDateString()]} />

        <SectionHalfAndHalf fieldName={[`${workspace.phoneNumber}`, i18n?.t('export.form.coa-section.analysis-request-id') || '']} fieldValue={['', analysis.code]} />

        {/* Sample information */}
        <SectionHeader header={[i18n?.t('export.form.sample-information-section.title') || '']} />
        <SectionHalfAndHalf
          fieldName={[i18n?.t('export.form.sample-information-section.sample-name') || '', i18n?.t('export.form.sample-information-section.sample-received-date') || '']}
          fieldValue={[analysis.name, RECEIVED_DATE]}
        />
        <SectionHalfAndHalf
          fieldName={[i18n?.t('export.form.sample-information-section.sample-id') || '', i18n?.t('export.form.sample-information-section.temperature') || '']}
          fieldValue={[SAMPLE_ID, TEMPERATURE]}
        />
        <SectionHalfAndHalf
          fieldName={[i18n?.t('export.form.sample-information-section.laboratory-id') || '', i18n?.t('export.form.sample-information-section.analysis-date') || '']}
          fieldValue={[LABO_ID, new Date(analysis.createdAt ?? new Date()).toLocaleDateString()]}
        />
        <SectionHalfAndHalf fieldName={[i18n?.t('export.form.sample-information-section.method-reference') || '']} fieldValue={[METHOD_REF]} />

        {/* Results table */}
        <SectionHeader header={[i18n?.t('export.form.result-table-section.title') || '']} />

        <Box marginHorizontal="$10" padding="$0.5">
          <Box marginHorizontal="$1">
            <Box style={{ flexDirection: 'row', borderBottomWidth: 1 }}>
              <Text style={{ width: '33%', fontWeight: 'bold', fontSize: 'medium' }}>{i18n?.t('export.form.result-table-section.header.pathogen')}</Text>
              <Text style={{ width: '33%', fontWeight: 'bold', fontSize: 'medium' }}>{i18n?.t('export.form.result-table-section.header.probability')}</Text>
              <Text style={{ width: '34%', fontWeight: 'bold', fontSize: 'medium' }}>{i18n?.t('export.form.result-table-section.header.confirmed')}</Text>
            </Box>
            {Object.entries(analysis?.results ?? {})
              ?.sort(([, aprobability], [, bprobability]) => bprobability - aprobability)
              ?.slice(0, 5)
              ?.map(([pathogen, probability], index) => (
                <Box key={index} style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '33%', fontSize: 'medium' }}>{pathogen}</Text>
                  <Text style={{ width: '33%', fontSize: 'medium' }}>{((probability as number) * 100).toFixed(2)}%</Text>
                  {index === 0 && <Text style={{ width: '34%', fontSize: 'medium' }}>X</Text>}
                </Box>
              ))}
          </Box>
        </Box>

        {/* Signature */}
        <Box style={{ flexDirection: 'row', padding: '5px', paddingTop: '40px' }}>
          <Text style={{ width: '50%', fontSize: 'medium' }}>{i18n?.t('export.form.signature-section.authorized-by', { user: AUTHORIZED_BY })}</Text>
          <Text style={{ width: '10%', fontSize: 'medium' }}>{i18n?.t('export.form.signature-section.signature')}</Text>
          <Text style={{ width: '40%', fontSize: 'medium', borderBottomWidth: '1px' }}></Text>
        </Box>
      </VStack>
    </>
  );
};
